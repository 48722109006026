<template>
  <div class="main-validation-container" data-cy="token-validation-page">
    <div class="d-flex flex-column">
      <h2 v-if="title" class="text-center" style="color: var(--primary)">
        {{ title }}
      </h2>
      <!-- Mobile -->
      <div
        v-if="!isLoading && $vuetify.breakpoint.smAndDown"
        class="d-flex flex-column"
      >
        <p class="text-center body-text my-5">
          {{ $t("reservation.verify_code_label") }}
        </p>
        <p class="text-center body-text">
          {{ $t("reservation.send_code_to") }}
        </p>
        <div class="d-flex justify-center align-center my-5">
          <z-country-flag
            v-if="!auth.validationMethod.includes('@')"
            class="mr-2"
            rounded
            :country="phoneCountryCode"
          />
          <p class="text-center body-bold">
            <span v-if="!auth.validationMethod.includes('@')">+</span>
            {{ auth.validationMethod }}
          </p>
        </div>
        <z-mfa-input
          data-cy="pin-input"
          inputmode="numeric"
          autofocus
          :value="auth.pin"
          :length="5"
          style="width: 80%; align-self: center"
          @input="$emit('change', $event)"
          @finish="$emit('finish')"
        />
        <p class="text-center body-text my-5">
          {{ $t("reservation.didnt_receive_code") }}
          <a
            v-if="!isTimerOn"
            data-cy="resend-code"
            class="text-decoration-underline"
            @click="$emit('resend')"
          >
            {{ $t("reservation.resend") }}</a
          >
          <span
            v-if="isTimerOn"
            data-cy="waiting-minutes"
            class="body-bold mr-2"
          >
            {{ $t("welcome.wait_for") + " " + waitingMinutes }}
          </span>
          <z-circular-progress
            v-if="isTimerOn"
            color="var(--primary)"
            value="20"
            size="17"
            width="4"
            rotate
            indeterminate
          />
        </p>
        <z-btn
          width="100%"
          class="my-5"
          :disabled="isLoading"
          :text="backBtnText"
          @click="$emit('back')"
        />
        <div style="border-top: 1px solid var(--black-100); width: 100%" />
        <a
          v-if="hasFillRecord"
          class="text-decoration-underline text-center my-5"
          @click="$emit('unrecognized')"
        >
          {{ $t("reservation.dont_recognize_data") }}
        </a>
      </div>
      <!-- Desktop -->
      <div v-else-if="!isLoading" class="d-flex flex-column mb-2">
        <p class="body-text my-5">
          {{ $t("reservation.verify_code_label") }}
        </p>
        <div class="d-flex justify-center align-center mb-5">
          <p class="body-text mr-2">
            {{ $t("reservation.send_code_to") }}
          </p>
          <z-country-flag
            v-if="!auth.validationMethod.includes('@')"
            rounded
            country="br"
            class="mr-2"
          />
          <p class="body-bold">
            <span v-if="!auth.validationMethod.includes('@')">+</span
            >{{ auth.validationMethod }}
          </p>
        </div>
        <z-mfa-input
          data-cy="pin-input"
          inputmode="numeric"
          autofocus
          :value="auth.pin"
          :length="5"
          style="width: 70%; align-self: center"
          @input="$emit('change', $event)"
          @finish="$emit('finish')"
        />
        <p class="text-center body-text my-5">
          {{ $t("reservation.didnt_receive_code") }}
          <a
            v-if="isTimerOn === false"
            data-cy="resend-code"
            class="text-decoration-underline"
            @click="$emit('resend')"
          >
            {{ $t("reservation.resend") }}</a
          >
          <span
            v-if="isTimerOn"
            data-cy="waiting-minutes"
            class="body-bold mr-2"
            >Aguarde {{ waitingMinutes }}
          </span>
          <z-circular-progress
            v-if="isTimerOn"
            color="var(--primary)"
            value="20"
            size="17"
            width="4"
            rotate
            indeterminate
          />
        </p>
        <z-btn
          width="130"
          style="align-self: flex-end"
          :text="$t('reservation.back_button')"
          @click="$emit('back')"
        />
      </div>

      <div
        v-else
        class="d-flex justify-center align-center mb-2"
        style="height: 400px"
      >
        <z-circular-progress
          color="var(--primary)"
          value="20"
          size="60"
          width="10"
          rotate
          indeterminate
        />
      </div>
    </div>
  </div>
</template>

<script>
import find from "lodash/find"
import { computed } from "vue"

import i18n from "@/i18n"

export default {
  props: {
    contacts: {
      type: Array,
      required: false,
      default: () => [],
    },

    auth: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      default: "",
    },
    validationType: {
      type: String,
      default: "",
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isTimerOn: {
      type: Boolean,
      default: false,
    },
    hasFillRecord: {
      type: Boolean,
      default: false,
    },
    backBtnText: {
      type: String,
      default: i18n.tc("reservation.back_guest_list"),
    },
    waitingMinutes: {
      type: String,
      default: "",
    },
  },

  setup(props) {
    const phoneCountryCode = computed(() => {
      const phone = find(props.contacts, { type: "mobile" })
      return phone ? phone.country : "br"
    })

    return {
      phoneCountryCode,
    }
  },
}
</script>

<style lang="scss" scoped>
.main-validation-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
